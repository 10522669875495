import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { Container } from 'components/ui/deprecated/Layout'
import { TextField } from 'components/ui/deprecated/TextField'
import { addAlphaToColor } from 'lib/style'

export const AllEntriesWrapper = styled(Container)<{ isLoading?: boolean }>`
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;

  ${({ theme }) => theme.media.lg} {
    ${({ isLoading }) =>
      isLoading &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `}
  }
`

export const AllEntriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const AllEntriesFilters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 0.5rem;

  & > * {
    margin-bottom: 1rem;
  }

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

export const FiltersTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem 2rem 0rem;
  border-top: 1px dashed
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};

  ${({ theme }) => theme.media.lg} {
    padding: 2rem 0rem;
  }
`

export const Wrapper = styled.div<{ showFilters?: boolean }>`
  display: flex;

  ${({ theme }) => theme.media.lg} {
    gap: ${({ showFilters }) => (showFilters ? '2rem' : '0rem')};
  }
`

export const FiltersWrapper = styled.div<{ showFilters?: boolean }>`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-direction: column;
    width: ${({ showFilters }) => (showFilters ? '19.0625rem' : '0rem')};
    opacity: ${({ showFilters }) => (showFilters ? '1' : '0')};
    height: 100%;
    flex-shrink: 0;
    transition: all ${({ theme }) => theme.transitions.ease('200ms')};
    z-index: 1;
  }
`

export const FiltersInnerWrapper = styled.div<{ open?: boolean }>`
  width: 19.0625rem;
  padding-bottom: ${({ open }) => (open ? '2rem' : '0rem')};
  border-bottom: 1px solid
    ${({ theme, open }) =>
      open
        ? addAlphaToColor(theme.colors.foreground.default, 10)
        : 'transparent'};
`

export const ServiceWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};
`

export const ServiceContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
`

export const Collapsable = styled(motion.div)`
  ${({ theme }) => theme.media.lg} {
    margin-top: 1rem;
  }
`

export const ItemEntry = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
`

export const WrapperClear = styled.div`
  padding-top: 2rem;
`

export const StyledTextField = styled(TextField)`
  padding-left: 3rem;
  height: 2.5rem;
  width: auto;

  ${({ theme }) => theme.media.md} {
    width: 18.75rem;
  }
`

export const WrapperTumbs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  z-index: 2;
  width: 100%;
`

export const WrapperSearch = styled.div`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: block;
    position: relative;
  }
`

export const WrapperSearchMobile = styled.div`
  display: block;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`
