import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { ServiceThumb } from 'components/blocks/sections/directories/thumbs/ServiceThumb'
import { Icon } from 'components/ui'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { TextField } from 'components/ui/deprecated/TextField'
import { Theme } from 'components/ui/deprecated/Theme'
import useDebounce from 'lib/hooks/useDebounce'
import useDrawerLinks from 'lib/hooks/useDrawerLinks'
import { useTranslation } from 'lib/i18n'
import { Storyblok } from 'lib/storyblok'
import { ServiceDetailPageStoryblok } from 'lib/storyblok/types'
import { getStoryblokCacheValue } from 'lib/utils/content'

import {
  AllEntriesWrapper,
  Wrapper,
  WrapperSearch,
  WrapperSearchMobile,
} from './AllEntriesLayout'
import { Empty } from './Empty'
import { Loader } from './Loader'
import { MobileSearch } from './MobileSearch'

type Props = {
  bySlugs: string
}

export type QueryType = {
  category?: string | null
  clinic?: string | null
}

export const ServiceEntries = ({ bySlugs }: Props): JSX.Element => {
  const linkRef = useRef(null)
  const linkRefMobile = useRef(null)
  useDrawerLinks(linkRef.current)
  useDrawerLinks(linkRefMobile.current)

  const router = useRouter()
  const { isPreview, query } = useRouter()
  const { i18n } = useTranslation()

  const [loading, setLoading] = useState(true)

  const [data, setData] = useState<
    ISbStoryData<ServiceDetailPageStoryblok>[] | null
  >(null)
  const [error, setError] = useState(false)

  /** Search */
  const [search, setSearch] = useState('')
  const [searchMobilePanel, setSearchMobilePanel] = useState(false)
  const debouncedSearch = useDebounce(search, 500)

  const fetch = useCallback(async () => {
    setLoading(true)
    setError(false)

    try {
      const {
        data: { stories },
      } = await Storyblok.get('cdn/stories', {
        version: isPreview ? 'draft' : 'published',
        by_slugs: bySlugs,
        sort_by: 'updated_at:desc',
        content_type: 'service-detail-page',
        search_term: debouncedSearch,
        excluding_fields: 'body,seo',
        cv: getStoryblokCacheValue(isPreview),
      })
      setData(stories)
    } catch {
      setError(true)
    }

    setLoading(false)
  }, [isPreview, bySlugs, query, debouncedSearch])

  useEffect(() => {
    if (router.isReady) {
      fetch()
    }
  }, [fetch, router.isReady])

  return (
    <Theme colorTheme="light">
      <Container ref={linkRef} isLoading={loading} variant="normal">
        <SearchContainer>
          <WrapperSearch>
            <SearchInput
              variant="input"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder={i18n('searchServices')}
              className="w-[486px] h-12 rounded-3"
            />

            <Icon
              icon="search"
              size={20}
              className="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none"
            />
          </WrapperSearch>

          <WrapperSearchMobile>
            <OldButton
              variant="ghost"
              size="small"
              onClick={() => setSearchMobilePanel((prev) => !prev)}
              className="w-10 mt-1 mb-2"
            >
              <Icon icon="search" />
            </OldButton>
          </WrapperSearchMobile>
        </SearchContainer>

        <Wrapper showFilters={false}>
          <MobileSearch
            isVisible={searchMobilePanel}
            onClose={() => {
              setSearchMobilePanel(false)
              setSearch('')
            }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={i18n('searchServices')}
            loading={loading}
            debouncedSearch={debouncedSearch}
          >
            <ServiceGrid ref={linkRefMobile}>
              {data?.map((item) => {
                return <ServiceThumb item={item} key={item.id} />
              })}
            </ServiceGrid>
          </MobileSearch>
          {data?.length ? (
            <ServicesGrid>
              {data?.map((item) => (
                <ServiceThumb item={item} key={item.uuid} />
              ))}
            </ServicesGrid>
          ) : loading ? (
            <Loader />
          ) : (
            <Empty isError={error} className="w-full" />
          )}
        </Wrapper>
      </Container>
    </Theme>
  )
}

export const Container = styled(AllEntriesWrapper)`
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 2.5rem;

  ${({ theme }) => theme.media.md} {
    padding-bottom: 7.5rem;
  }
`

export const SearchContainer = styled.div`
  padding-top: 1.25rem;
`

export const SearchInput = styled(TextField)`
  padding-left: 3rem;
  height: 2.5rem;
  width: auto;
`

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.25rem;
  z-index: 2;

  padding-top: 0.75rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ServiceGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`
