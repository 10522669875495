import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { CMSLink } from 'components/blocks/deprecated/CMSLink'
import { Button, Icon } from 'components/ui'
import { Link } from 'components/ui/deprecated/Link'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { Text } from 'components/ui/deprecated/Text'
import { Theme } from 'components/ui/deprecated/Theme'
import { DYNAMIC_PHONE_NUMBER_CLASSNAME } from 'lib/constants'
import { useDataContext } from 'lib/contexts/dataContext'
import useOutsideClick from 'lib/hooks/useOutsideClick'
import { useTranslation } from 'lib/i18n'
import { addAlphaToColor, dashedLine } from 'lib/style'
import { getAnchorFromCmsLink } from 'lib/utils/content'
import { GTMEvents, trackEvent } from 'lib/utils/gtm'

export function FloatingCTAS({
  animatePosition = '100%',
}: {
  animatePosition?: '100%' | '-100%'
}): JSX.Element | null {
  const { i18n } = useTranslation()
  const { storyUuid, floating } = useDataContext()
  const [open, setOpen] = useState(false)
  const panelRef = useRef<HTMLDivElement | null>(null)
  useOutsideClick(panelRef, () => {
    if (open) setOpen(false)
  })

  if (!floating) {
    return null
  }

  const contactPage = floating?.content?.contact_us
  const phones = floating?.content?.phones
  const contactTitle = floating?.content?.title
  const contactUsBtnLabel = floating?.content?.contact_us_btn

  if (storyUuid && floating.content.blacklist?.includes(storyUuid)) return null

  return (
    <>
      <StyledTheme colorTheme="dark-purple">
        <ButtonsHolder
          initial={{
            x: animatePosition,
          }}
          animate={{ x: open ? animatePosition : 0 }}
          transition={{
            delay: open ? 0 : 0.5,
            type: 'spring',
            damping: 30,
            stiffness: 800,
          }}
        >
          <CircleButton
            role="button"
            tabIndex={0}
            onClick={() => {
              setOpen(true)
            }}
          >
            <Icon icon="phone" size={20} />
          </CircleButton>

          {contactPage && (
            <CircleButton
              as="a"
              rel={getAnchorFromCmsLink(contactPage).rel}
              href={getAnchorFromCmsLink(contactPage).href}
              target={getAnchorFromCmsLink(contactPage).target}
              className="mt-2"
            >
              <Icon icon="mail" size={20} />
            </CircleButton>
          )}
        </ButtonsHolder>

        {open && (
          <Panel
            ref={panelRef}
            initial={{ x: animatePosition }}
            animate={{
              x: open ? 0 : animatePosition,
              transition: {
                delay: 0.2,
                type: 'spring',
                damping: 50,
                stiffness: 800,
              },
            }}
            exit={{ x: animatePosition }}
            transition={{ type: 'spring', damping: 40, stiffness: 500 }}
          >
            <PanelHeader>
              <Text as="h3" variant="title/small/regular">
                {contactTitle || i18n('floating.contacts')}
              </Text>
              <Close
                variant="ghost"
                leftIcon="close"
                size="small"
                onClick={() => setOpen(false)}
              />
            </PanelHeader>

            <PhoneList>
              {phones?.map((phone) => (
                <Phone key={phone._uid}>
                  <Text as="p" variant="sixteen">
                    {phone.title}
                  </Text>
                  <Link
                    className={
                      phone.isDynamic ? DYNAMIC_PHONE_NUMBER_CLASSNAME : ''
                    }
                    onClick={() => {
                      trackEvent(GTMEvents.Call, {
                        'tfp-phone-number': phone.phone || '',
                      })
                    }}
                    href={`tel:${phone.phone}`}
                  >
                    <Text
                      className={
                        phone.isDynamic ? DYNAMIC_PHONE_NUMBER_CLASSNAME : ''
                      }
                      as="p"
                      variant="sixteen"
                    >
                      {phone.phone}
                    </Text>
                  </Link>
                </Phone>
              ))}
            </PhoneList>

            {contactPage && (
              <Footer>
                <CMSLink href={contactPage} variant="solid" size="small" isFull>
                  {contactUsBtnLabel || i18n('floating.contactUs')}
                </CMSLink>
              </Footer>
            )}
          </Panel>
        )}
      </StyledTheme>

      <ButtonsHolderMobile>
        {phones && (
          <Button size="sm" icon="phone" href={`tel:${phones[0].phone}`}>
            {phones[0].phone}
          </Button>
        )}

        {contactPage && (
          <Button
            as="a"
            size="sm"
            variant="secondary"
            rel={getAnchorFromCmsLink(contactPage).rel}
            href={getAnchorFromCmsLink(contactPage).href}
            target={getAnchorFromCmsLink(contactPage).target}
          >
            {contactUsBtnLabel || i18n('floating.contactUs')}
          </Button>
        )}
      </ButtonsHolderMobile>
    </>
  )
}

const StyledTheme = styled(Theme)`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: block;
  }
`

const ButtonsHolder = styled(motion.div)`
  position: fixed;

  left: unset;
  right: 0;
  bottom: 1.5rem;

  padding-left: unset;
  padding-right: 1.5rem;

  z-index: ${({ theme }) => theme.zIndex.high};
`

const CircleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.background.default};
  color: ${({ theme }) => theme.colors.foreground.default};

  height: 2.5rem;
  width: 2.5rem;

  cursor: pointer;

  transition: opacity ${({ theme }) => theme.transitions.ease()};
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`

const Panel = styled(motion.div)`
  position: fixed;
  left: unset;
  right: 0;
  bottom: 1.5rem;

  box-shadow: ${({ theme }) => theme.shadows.high};

  color: ${({ theme }) => theme.colors.foreground.default};
  background-color: ${({ theme }) => theme.colors.background.default};

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;

  width: 23rem;
  max-width: 100vw;

  z-index: ${({ theme }) => theme.zIndex.highest + 1};

  padding: 1rem 0;
`

const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 1rem;

  margin-bottom: 1rem;
`

const Close = styled(OldButton)`
  width: 2rem;
  height: 2rem;

  color: ${({ theme }) => theme.colors.foreground.default};
  border-color: ${({ theme }) => theme.colors.foreground.subtle};
`

const PhoneList = styled.ul`
  padding: 0 1rem;

  max-height: 50vh;

  overflow: auto;
`

const Phone = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.75rem 0;

  ${({ theme }) => dashedLine('bottom', theme.colors.foreground.subtle)}
`

const Footer = styled.div`
  padding: 0 1rem;
  margin-top: 1rem;
`
const ButtonsHolderMobile = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => theme.colors.palette.beige.default};
  padding: 0.5rem;
  gap: 0.5rem;
  z-index: ${({ theme }) => theme.zIndex.highest};
  border-top: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`
